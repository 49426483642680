(function () {
	'use strict';

	const break_a = 768;
	const break_b = 767;
	const ua = UAParser();

	$(function () {
    // UAクラス付与
    if (ua.device.type == undefined) {
      $("html").addClass("ua_pc");
    } else {
      $("html").addClass("ua_" + ua.device.type);
    }
    $("html").addClass(ua.browser.name).addClass(ua.os.name);

    // viewport調整
    if (ua.device.type == "tablet") {
      $("meta[name='viewport']").attr(
        "content",
        "width=1200,user-scalable=yes,maximum-scale=2.0"
      );
    }

    // ページトップ
    $(".footer__page-top").on("click", (event) => {
      $("html, body").animate({ scrollTop: 0 }, 400, "swing");
    });

    $(window).on("load scroll", () => {
      // ヘッダークラス付与
      if ($(window).scrollTop() > 200) {
        $("body").addClass("is-header-bg");
      } else {
        $("body").removeClass("is-header-bg");
      }
      // ヘッダー横スクロール対応
      $(".header").css("left", -$(window).scrollLeft());
    });

    // ヘッダー開閉
    $(".header__humberger").on("click", (event) => {
      $("body").toggleClass("is-navi-open");
      return false;
    });
    $(".header__overlay").on("click", (event) => {
      $("body").removeClass("is-navi-open");
    });

    // Gメニュー
    $(".header__gmenu__link:not([href])").on("click", (event) => {
      $(event.currentTarget)
        .parents(".header__gmenu__item")
        .toggleClass("is-second-open");
    });
    $(".header__gmenu__second-menu__link:not([href])").on("click", (event) => {
      $(event.currentTarget)
        .parents(".header__gmenu__second-menu__item")
        .toggleClass("is-third-open");
    });

    // PC子メニュー
    const headerSecondMenu = (() => {
      const triggerElement = "header__quickmenu__item--has-child";
      const trigger = $(`.${triggerElement}`);
      const openClass = "is-second-open";

      const menuToggle = (target) => {
        trigger.not(target).removeClass(openClass);
        $(target).toggleClass(openClass);
      };

      trigger.on("click", (event) => menuToggle(event.currentTarget));

      // PCはホバー時も処理
      if (ua.device.type === undefined) {
        // PCの場合
        trigger.on({
          mouseenter: (event) => menuToggle(event.currentTarget),
          mouseleave: (event) => menuToggle(event.currentTarget),
        });
      }
    })();

    // inview ※要素が画面上に入ったときに処理する
    $(".js-inview").on("inview", function (event, isInView) {
      if (isInView) {
        $(this).addClass("is-view");
      }
    });

    //  アンカーリンク
    $('a[href^="#"]').on("click", (event) => {
      const fire = $(event.currentTarget);
      const anchor_href = fire.attr("href");
      const target = $(
        anchor_href == "#" || anchor_href == "" ? "html" : anchor_href
      );
      const position = target.offset().top;
      const speed = 400;
      let header_h = $(".header").outerHeight();
      if (window.innerWidth > break_a) {
        header_h = 80;
      }
      $("html, body").animate(
        { scrollTop: position - header_h },
        speed,
        "swing"
      );
      return false;
    });
    //　アクセス時のアンカー処理
    let location_hash = location.hash;
    if (location_hash && $(location_hash).length) {
      let target_position =
        $(location_hash).offset().top - $(".header").outerHeight();
      $("html,body").animate({ scrollTop: target_position }, 600);
    }

		//  アンカーリンク
		$('a[href^="#"]').on('click', event =>{
			const fire = $(event.currentTarget);
			const anchor_href =  fire.attr("href");
			const target = $(anchor_href == "#" || anchor_href == "" ? 'html' : anchor_href);
			const position = target.offset().top;
			const speed = 400;
			let header_h = $('.header').outerHeight();
			if(window.innerWidth > break_a) {
				header_h = 80;
			}
			$("html, body").animate({scrollTop:position - header_h}, speed, "swing");
			return false;		
		});
		//　アクセス時のアンカー処理
		const anchorCheck = (() => {
			const location_hash = location.hash;
			if (location_hash && $(location_hash).length) {
				const target_position = $(location_hash).offset().top - $('.header').outerHeight();
				$('html,body').animate({scrollTop: target_position}, 600);
			}		
		})();
    // フォーム系の処理
    /* AjaxZip3 表示時も起動 ※デフォルトでは入力時のみ */
    if ($(".p-country-name").length && $('input[name="zipCode1"]').val()) {
      AjaxZip3.zip2addr("zipCode1", "zipCode2", "pref", "address1");
    }

    $(".footer__pagetop").on("click", (event) => {
      $("html, body").animate({ scrollTop: 0 }, 400, "swing");
    });

    if ($(".page-top").length) {
      // mv
      $(".top-mv").addClass("animate");
      // 建築 スライダー
      $(".top-estate__photo__list").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 9000,
        arrows: false,
        cssEase: "linear",
        variableWidth: true,
      });
    }


    // スライダー枚数によって固定させる
    $('.cases-gallery__thumbnail__list').each(function() {
      const $slider = $(this);
      const slideCount = $slider.find('.cases-gallery__thumbnail__item').length;

      if (slideCount <= 7 && slideCount >= 6 ) {
        $slider.addClass('fixed-thumbnails');
      } else if (slideCount <= 5) {
        $slider.addClass('fixed-thumbnails-sp');
      } else {
        $slider.removeClass('fixed-thumbnails fixed-thumbnails-sp');
      }
    });


    // if($('page-architecture-cases').length) {
    // 施工実績　スライダー
    // メイン画像

    const thumbItem = $(".cases-gallery__photo__list");
    const thumbItemNav = $(".cases-gallery__thumbnail__list");

    // $(function () {
      thumbItem.each(function () {
        $(this).slick({
          asNavFor: "#" + $(this).attr("id") + "-nav",
          arrows: true, // 矢印表示
          prevArrow:
            '<div class="slick-prev"><img src="/assets/img/business/architecture/cases/pct_gallery_arrow_prev.svg" alt="prev"></div>',
          nextArrow:
            '<div class="slick-next"><img src="/assets/img/business/architecture/cases/pct_gallery_arrow_next.svg" alt="next"></div>',
          dots: true,
          appendDots: $(this).nextAll(".cases-gallery__dots"), // ドットナビゲーションの生成位置を変更
        });
      });
      thumbItemNav.each(function () {
        $(this).slick({
          asNavFor: "#" + $(this).attr("id").replace("-nav", ""),
          slidesToShow: 7, // サムネイルの表示数
          focusOnSelect: true, // サムネイルクリックを有効化
          arrows: false,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 768, // 399px以下のサイズに適用
              settings: {
                slidesToShow: 5,
              },
            },
          ],
        });
      });
    // });
  });

	// 同意チェック
	const formAgreeCheck = (() => {
		const agreeInput = document.querySelector('.js-agree-check');
		const agreeButton = document.querySelector('.js-agree-check-button');
		const agreeButtonClass = 'global-button';
		const agreeDisbiled = `${agreeButtonClass}--disabled`;
		if (!agreeInput && !agreeButton) return;
		if (!agreeButton.classList.contains(agreeButtonClass)) return;
		// クラス付与処理
		const agreeClassSet = () => {
			agreeInput.checked ? agreeButton.classList.remove(agreeDisbiled) : agreeButton.classList.add(agreeDisbiled);
		}
		// 表示時
		agreeClassSet();
		// inputクリック時
		agreeInput.onclick = () => {
			agreeClassSet();
		};
	})();

	// 要素の高さを揃える
	class EqualHeight {
		constructor(selector) {
			this.elements = document.querySelectorAll(selector);
			this.setEqualHeight();
			window.addEventListener('resize', () => this.setEqualHeight());
			window.addEventListener('load', () => this.setEqualHeight());
		}
		
		setEqualHeight() {
			let maxHeight = 0;
			this.elements.forEach(element => {
				element.style.height = '';
				const elementHeight = element.clientHeight;
				if (elementHeight > maxHeight) {
					maxHeight = elementHeight;
				}
			});
			this.elements.forEach(element => {
				element.style.height = `${maxHeight}px`;
			});
		}
	}
	
	const equalHeightInnerlinks = new EqualHeight('.inner-links__item');

	// アコーディオン開閉
	$('.accordion-box__head').on('click', event =>{
		const box = event.currentTarget.closest('.accordion-box');
		$(box).toggleClass('is-show');
		if($(".accordion-box").hasClass("is-show")) {
			$(".cases-selection").find(".accordion-box__toggle-icon").text("CLOSE");
		} else {
			$(".cases-selection").find(".accordion-box__toggle-icon").text("OPEN");
		}
	});

	// selectでURL切り替え
	const selectChangeUrl = (() => {
		const select = document.querySelectorAll('.js-select-url');
		select.forEach((tgt) => {
			const documentUrl = document.location.href;
			for (let i = 0; i < tgt.options.length; i++) {
				const option = tgt.options[i];
				if (option.value === documentUrl) {
					option.selected = true; 
					break;
				} else {
					tgt.options[0].selected = true; 
				}
			}
			tgt.addEventListener('change', () => {
				document.location.href = tgt.options[tgt.selectedIndex].value;
			});
		});
	})();	

}());